var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.renderizza
    ? _c("div", [
        _vm.getDomanda.type === "number"
          ? _c(
              "fieldset",
              { staticClass: "q-pa-none qma-none no-border" },
              [
                _c("legend", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                _c("q-input", {
                  ref: _vm.getDomanda.type,
                  attrs: {
                    type: "number",
                    outlined: "",
                    dense: "",
                    name: _vm.getDomanda.name,
                    disable: _vm.getDomanda.is_disabled,
                    filled: _vm.getDomanda.is_disabled,
                    rules: [
                      function (val) {
                        return !_vm.isNotValid(val) || _vm.errorMessage
                      },
                    ],
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("update:domanda", _vm.domanda)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prepend",
                        fn: function () {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.getDomanda.label_prepend) + " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.getDomanda.label_append) + " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3396353609
                  ),
                  model: {
                    value: _vm.getDomanda.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.getDomanda, "value", $$v)
                    },
                    expression: "getDomanda.value",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type === "percentuale"
          ? _c(
              "fieldset",
              { staticClass: "q-pa-none qma-none no-border" },
              [
                _c("legend", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                _c("q-input", {
                  ref: _vm.getDomanda.type,
                  attrs: {
                    type: "number",
                    outlined: "",
                    dense: "",
                    name: _vm.getDomanda.name,
                    disable: _vm.getDomanda.is_disabled,
                    filled: _vm.getDomanda.is_disabled,
                    rules: [
                      function (val) {
                        return !_vm.isNotValid(val) || _vm.errorMessage
                      },
                    ],
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("update:domanda", _vm.getDomanda)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [_vm._v(" % ")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3245807702
                  ),
                  model: {
                    value: _vm.getDomanda.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.getDomanda, "value", $$v)
                    },
                    expression: "getDomanda.value",
                  },
                }),
                _vm._v(" " + _vm._s(_vm.getPremioRiferimento) + " Euro "),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type === "select"
          ? _c(
              "fieldset",
              { staticClass: "q-pa-none qma-none no-border" },
              [
                _c("legend", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                _vm.getDomanda.type === "select"
                  ? _c("q-select", {
                      ref: _vm.getDomanda.type,
                      attrs: {
                        dense: "",
                        "virtual-scroll-slice-size": "0",
                        "virtual-scroll-item-size": "0",
                        name: _vm.getDomanda.name,
                        outlined: "",
                        "emit-value": "",
                        "map-options": "",
                        disable: _vm.getDomanda.is_disabled,
                        filled: _vm.getDomanda.is_disabled,
                        options: _vm.getDomanda.options,
                        rules: [
                          function (val) {
                            return !_vm.isNotValid(val) || _vm.errorMessage
                          },
                        ],
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("update:domanda", _vm.getDomanda)
                        },
                      },
                      model: {
                        value: _vm.getDomanda.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.getDomanda, "value", $$v)
                        },
                        expression: "getDomanda.value",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type == "input_select"
          ? _c(
              "fieldset",
              { staticClass: "q-pa-none qma-none no-border" },
              [
                _c("legend", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                _c("q-select", {
                  ref: "input_select",
                  attrs: {
                    "emit-value": "",
                    dense: "",
                    "map-options": "",
                    name: _vm.getDomanda.name,
                    "virtual-scroll-slice-size": "0",
                    "virtual-scroll-item-size": "0",
                    outlined: "",
                    "use-input": "",
                    "input-debounce": "0",
                    disable: _vm.getDomanda.is_disabled,
                    filled: _vm.getDomanda.is_disabled,
                    options: _vm.getDomanda.options,
                    rules: [
                      function (val) {
                        return !_vm.isNotValid(val) || _vm.errorMessage
                      },
                    ],
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.onBlur()
                    },
                    input: function ($event) {
                      return _vm.$emit("qq-domanda-change", _vm.getDomanda)
                    },
                    filter: _vm.onInputSelectFilter,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "no-option",
                        fn: function () {
                          return [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  { staticClass: "text-grey" },
                                  [_vm._v(" Nessun risultato ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2139249595
                  ),
                  model: {
                    value: _vm.getDomanda.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.getDomanda, "value", $$v)
                    },
                    expression: "getDomanda.value",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type === "date"
          ? _c(
              "fieldset",
              { staticClass: "q-pa-none qma-none no-border" },
              [
                _c("legend", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                _c("q-input", {
                  ref: _vm.getDomanda.type,
                  attrs: {
                    outlined: "",
                    mask: "##/##/####",
                    dense: "",
                    name: _vm.getDomanda.name,
                    rules: [
                      function (val) {
                        return !_vm.isNotValid(val) || _vm.errorMessage
                      },
                    ],
                    disable: _vm.getDomanda.is_disabled,
                    filled: _vm.getDomanda.is_disabled,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("update:domanda", _vm.getDomanda)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c(
                              "q-icon",
                              {
                                staticClass: "cursor-pointer",
                                attrs: { name: "event" },
                              },
                              [
                                _c(
                                  "q-popup-proxy",
                                  {
                                    attrs: {
                                      "transition-show": "scale",
                                      "transition-hide": "scale",
                                    },
                                  },
                                  [
                                    _c(
                                      "q-date",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.visible,
                                            expression: "visible",
                                          },
                                        ],
                                        attrs: { mask: "DD/MM/YYYY" },
                                        model: {
                                          value: _vm.getDomanda.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.getDomanda,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "getDomanda.value",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row items-center justify-end",
                                          },
                                          [
                                            _c("q-btn", {
                                              directives: [
                                                {
                                                  name: "close-popup",
                                                  rawName: "v-close-popup",
                                                },
                                              ],
                                              attrs: {
                                                label: "Chiudi",
                                                flat: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3195312016
                  ),
                  model: {
                    value: _vm.getDomanda.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.getDomanda, "value", $$v)
                    },
                    expression: "getDomanda.value",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type === "text"
          ? _c(
              "fieldset",
              { staticClass: "q-pa-none qma-none no-border" },
              [
                _c("legend", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                _vm.getDomanda.type == "text"
                  ? _c("q-input", {
                      ref: _vm.getDomanda.type,
                      staticClass: "textbox",
                      attrs: {
                        outlined: "",
                        dense: "",
                        name: _vm.getDomanda.name,
                        disable: _vm.getDomanda.is_disabled,
                        filled: _vm.getDomanda.is_disabled,
                        placeholder: _vm.getDomanda.placeholder,
                        rules: [
                          function (val) {
                            return !_vm.isNotValid(val) || _vm.errorMessage
                          },
                        ],
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("update:domanda", _vm.getDomanda)
                        },
                      },
                      model: {
                        value: _vm.getDomanda.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.getDomanda, "value", $$v)
                        },
                        expression: "getDomanda.value",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type === "textarea"
          ? _c(
              "fieldset",
              { staticClass: "q-pa-none qma-none no-border" },
              [
                _c("legend", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                _c("q-input", {
                  ref: _vm.getDomanda.type,
                  attrs: {
                    outlined: "",
                    dense: "",
                    name: _vm.getDomanda.name,
                    type: "textarea",
                    disable: _vm.getDomanda.is_disabled,
                    filled: _vm.getDomanda.is_disabled,
                    placeholder: _vm.getDomanda.placeholder,
                    rules: [
                      function (val) {
                        return !_vm.isNotValid(val) || _vm.errorMessage
                      },
                    ],
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("update:domanda", _vm.getDomanda)
                    },
                  },
                  model: {
                    value: _vm.getDomanda.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.getDomanda, "value", $$v)
                    },
                    expression: "getDomanda.value",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type === "group_checkbox"
          ? _c("div", { attrs: { align: "left" } }, [
              _c(
                "fieldset",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.visible,
                      expression: "visible",
                    },
                  ],
                  staticClass: "q-mb-md",
                },
                [
                  _c("legend", [
                    _c("strong", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                  ]),
                  _c("q-option-group", {
                    attrs: {
                      type: "checkbox",
                      name: _vm.getDomanda.name,
                      options: _vm.getDomanda.options,
                      disable: _vm.getDomanda.is_disabled || _vm.disabled,
                      rules: [
                        function (val) {
                          return !_vm.isNotValid(val) || _vm.errorMessage
                        },
                      ],
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("update:domanda", _vm.getDomanda)
                      },
                    },
                    model: {
                      value: _vm.getDomanda.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.getDomanda, "value", $$v)
                      },
                      expression: "getDomanda.value",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.getDomanda.type === "checkbox"
          ? _c(
              "div",
              { attrs: { align: "center" } },
              [
                _c("q-checkbox", {
                  attrs: {
                    name: _vm.getDomanda.name,
                    label: _vm.getDomanda.label,
                    disable: _vm.getDomanda.is_disabled,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("update:domanda", _vm.getDomanda)
                    },
                  },
                  model: {
                    value: _vm.getDomanda.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.getDomanda, "value", $$v)
                    },
                    expression: "getDomanda.value",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type === "radio"
          ? _c(
              "fieldset",
              { staticClass: "q-pa-none qma-none no-border" },
              [
                _c("legend", [_vm._v(_vm._s(_vm.getDomanda.label))]),
                _c("q-option-group", {
                  attrs: {
                    type: "radio",
                    name: _vm.getDomanda.name,
                    options: _vm.getDomanda.options,
                    disable: _vm.getDomanda.is_disabled,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("update:domanda", _vm.getDomanda)
                    },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.isNotValid(_vm.getDomanda.value)
                    },
                  },
                  model: {
                    value: _vm.getDomanda.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.getDomanda, "value", $$v)
                    },
                    expression: "getDomanda.value",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.getDomanda.type === "big-radio"
          ? _c(
              "fieldset",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.visible,
                    expression: "visible",
                  },
                ],
                staticClass: "q-mb-md",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", {
                    staticClass: "cl-12 col-md-8",
                    domProps: { innerHTML: _vm._s(_vm.domanda.label) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "cl-12 col-md-4",
                      attrs: { align: "center" },
                    },
                    [
                      _c("q-option-group", {
                        attrs: {
                          options: _vm.getDomanda.options,
                          label: _vm.getDomanda.label,
                          name: _vm.getDomanda.name,
                          type: "radio",
                          disable: _vm.getDomanda.is_disabled,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$emit("update:domanda", _vm.getDomanda)
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.isNotValid(_vm.getDomanda.value)
                          },
                        },
                        model: {
                          value: _vm.getDomanda.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.getDomanda, "value", $$v)
                          },
                          expression: "getDomanda.value",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }